<template>
  <v-container>
    <div v-for="item in fields" :key="item.id">
      <!--Titles-->
      <v-row :class="item.id > 1 ? 'mt-3' : ''">
        <v-col cols="12" sm="6" md="6">
          <h4>{{ item.title }}</h4>
        </v-col>
      </v-row>
      <!--Inputs-->
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="6"
          v-for="input in item.inputs"
          :key="input.value"
          class="pb-0"
        >
          <!--Text Field-->
          <FormTextField
            :input="input"
            v-if="input.type === 'text'"
            @passChange="$emit('passChange', input)"
          />
          <!--Dropdown-->
          <FormDropdown
            :input="input"
            v-if="input.type === 'select'"
            @passChange="$emit('passChange', input)"
          />
          <!--Autocomplete-->
          <FormAutocomplete
            :input="input"
            v-if="input.type === 'autocomplete'"
            @passChange="$emit('passChange', input)"
          />
          <!--Date Picker-->
          <!--Image Upload-->
          <FormImageUpload
            :input="input"
            v-if="input.type === 'image-upload'"
            @passChange="$emit('passChange', input)"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "FormFields",
  props: ["fields", "firstLoad"],
  components: {
    FormTextField: () => import("../Forms/FormTextField.vue"),
    FormDropdown: () => import("../Forms/FormDropdown.vue"),
    FormAutocomplete: () => import("../Forms/FormAutocomplete.vue"),
    FormImageUpload: () => import("../Forms/FormImageUpload.vue"),
  },
  data() {
    return {};
  },
};
</script>